import React, { FC, useState } from 'react';
import { Stack } from '../../../components/common';
import { AgentCaseData } from '../types';
import { ReactComponent as StarRating } from '../../../assets/StarRating.svg';
import { ReactComponent as AutoRespond } from '../../../assets/auto_respond.svg';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { FeedbackForm } from './FeedbackForm';

export const FeedbackCol: FC<{
  caseData: AgentCaseData;
}> = (props) => {
  const { caseData } = props;
  const [value, setValue] = useState<number>(0);
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  return (
    <Stack gap={20} direction="vertical" align="flex-start">
      {caseData.feedbackRating && (
        <Stack direction="horizontal" gap={12}>
          <Stack direction="vertical" gap={9}>
            <Stack style={{ alignItems: 'center' }}>
              <StarRating />
              <p style={{ marginLeft: 4 }}>{caseData.feedbackRating}</p>
            </Stack>
            <Stack>
              <AutoRespond width={18} />
              <p>{caseData.feedbackComment?.length}</p>
            </Stack>
          </Stack>
          <p
            style={{
              fontSize: 14,
              color: '#5D5EFE',
              textDecorationLine: 'underline',
              cursor: 'pointer'
            }}
            onClick={() => {
              setShowFeedbackDialog(true);
            }}
          >
            view
          </p>
        </Stack>
      )}
      {!caseData.feedbackRating && (
        <Stack direction="vertical" gap={12}>
          <p
            style={{
              fontSize: 12,
              color: '#5D5EFE',
              textDecorationLine: 'underline',
              cursor: 'pointer'
            }}
            onClick={() => {
              setShowFeedbackDialog(true);
            }}
          >
            Write a comment
          </p>
          <Rating
            value={value}
            size="small"
            style={{ marginTop: 4 }}
            emptyIcon={<StarBorderIcon fontSize="inherit" />}
            onChange={(event, newValue) => {
              caseData.feedbackRating = newValue ? newValue : 0;
              setValue(newValue ? newValue : 0);
            }}
            precision={0.1}
            onClick={() => {
              setShowFeedbackDialog(true);
            }}
          />
        </Stack>
      )}
      {showFeedbackDialog && (
        <FeedbackForm
          caseData={caseData}
          open={showFeedbackDialog}
          setShowFeedbackDialog={setShowFeedbackDialog}
          ratingSelected={
            caseData.feedbackRating ? caseData.feedbackRating : value
          }
          setValue={setValue}
          view={caseData.feedbackRating ? true : false}
        />
      )}
    </Stack>
  );
};
